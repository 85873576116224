import { isVNR } from "./FobiCore";
import { toast } from 'react-toastify';
import { setScreen, setScreenVeranstaltung  } from './store/appNavigation/actions';
import { addVeranstaltungPanelSetVNR, setShowAddVeranslatungsPanel } from './store/veranstaltungen/actions';

export const getIntendedVNR = () => {
	const params = new URLSearchParams(window.location.search);
	const vnr = params.get('vnr');
	return vnr;
}
export const applyVNR = (store, vnr) => {
	if(!isVNR(vnr)){
		toast.warning("VNR ist ungültig");
		return;
	}
	const state = store.getState();
	const vnrLoaded = state && state.veranstaltungen[vnr];
	if(vnrLoaded){
		store.dispatch(setScreenVeranstaltung(vnr));
	}else{
		store.dispatch(setScreen("startPage"));
		store.dispatch(addVeranstaltungPanelSetVNR(vnr));
		store.dispatch(setShowAddVeranslatungsPanel(true));
	}
}


	/*
appNavigation: 
currentScreen:"veranstalterPage"
displayDialogQrcodeLinkToApp:false
saveStateToLocalStorage:true
selectedVNR:"2760602023000160003"
	*/


