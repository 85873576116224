class EivngCore{
  constructor(){
    this.cordovaPushNotification = null
  }
	resetSettings(){
    localStorage.clear(); this.reloadWindow();
  }
  reloadWindow(){
    window.location.reload();
  }
}
export function getAuthHeaderVnrPwd(vnr, pwd){
  //  ("Authorization", "Basic " + btoa(username + ":" + password));
  const headers = new Headers();
  headers.append('Accept', 'application/json; charset=UTF-8');
  headers.append('Authorization', "Basic "+ btoa(vnr + ":" + pwd));
  headers.append( 'Content-type', 'application/json; charset=UTF-8')
  return headers
}

export function getHeaderWithBearerAutorization(jwt){
  const headers = new Headers();
  headers.append('Accept', 'application/json; charset=UTF-8');
  headers.append('Authorization', `Bearer ${jwt}`);
  headers.append( 'Content-type', 'application/json; charset=UTF-8');
  return headers
}

export let eivngCore = new EivngCore();

/* Für Prototype: Datenbank bei der AEKWL */
// export const BACKEND_BASE_URL = "https://oriontest.laekh.de";
// export const BACKEND_KAMMERDIENER_PREFIX = "/butler";

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://butler-test.laekh.de/backend/v1";

export const BACKEND_KAMMERDIENER_PREFIX = "";

/* Für Localhost  */
// export const BACKEND_BASE_URL = "http://localhost:8082";
// export const BACKEND_KAMMERDIENER_PREFIX = "";

/* Für Enwickltung localhost with service worker. Prototyp, wird entfernt  */
//export const BACKEND_KAMMERDIENER_PREFIX = "/butler/og"

export const EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT = 96
export const MAX_ALLOWED_REF_PUNKTE = 12

export const mobileAppModus = window.cordova?true:false
