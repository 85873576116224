import React, {useEffect} from 'react';
import { connect, useSelector } from 'react-redux'
import EIVHeader from '../EIVHeader';
import  moment  from 'moment';
import { CSS_CLASSNAME_PANEL } from '../EIVCommons';
import { Alert, Button} from 'reactstrap'
import AddTeilnehmer from './AddTeilnehmer';
import ErfassteTeilnehmer  from './TeilnehmerMelden/ErfassteTeilnehmer';
import { isShowTeilnahmedatum, isVeranstaltungsBeginnInZukunft } from './TeilnehmerMelden/teilnahmedatum';
import Punktemeldungen from './Punktemeldungen'
import { meldeErfassteTeilnahmen, removeAllSeized } from '../store/punkteMeldung/teilnehmermeldenActionCreator';
import { setScreen, appScreens } from '../store/appNavigation/actions';
import { showConfirmDialog } from '../store/confirmDialog/actions';
import { fetchPunktemeldungen } from '../store/punkteMeldung/fetchPunktemeldungen';
import { Beispiele } from './Bespiele';
import { addTeilnahmeSetEFN } from '../store/punkteMeldung/actions';
import { Howl } from 'howler';
import { validateGemeldeteRefPunkte, validateRefPunkte } from './refPunkteValidator';

const soundMeldungSent = new Howl({
  src: ['./sounds/dramatic-camera-zoom-6393.mp3'] // Pfad zum Sound-File
});

const AlertContentWarningRefPunkte060 = (props) => {
	return (
		<p className='small'>
			Die Fortbildungsordnung der zuständigen Landesärztekammer regelt die Vergabe der Referentenpunkte. <br/>
			<b>Sie</b> sind für die Einhaltung dieser Regel <b>verantwortlich</b>. 
			Grundsätzlich ist die Vergabe der Ref.-Punkte in Hessen für die Veranstaltungen der Kategorien <b>(A)</b> und <b>(H)</b> vorgesehen. Darüber hinaus kann der WL/Moderator bei jeder Kategorie 1 Ref. Punkt erhalten, sofern dies von der zuständigen Landesärztekammer entsprechend geregelt ist.
		</p>
	)
}
const AlertContentWarningRefPunkteDefault = (props) => {
	return (
		<p className='small'>
			Die Vergabe von Punkten für Referenten, Moderatoren und die Wissenschaftliche Leitung regelt die Fortbildungsordnung der zuständigen Ärztekammer. 
			<b>Sie</b> sind für die Einhaltung dieser Regel <b>verantwortlich</b>. Bitte informieren Sie sich über die korrekte Vergabe von Ref/Mod/WL-Pkte.
		</p>
	)
}
const AlertContentWarningRefPunkte = (props) => {
	const { vnr } = props;
	const landKammerId = vnr.substring(0, 6);
	console.log(landKammerId);
	switch (landKammerId){
		case '276060':
			return( <AlertContentWarningRefPunkte060 /> );
		default:	
			return( <AlertContentWarningRefPunkteDefault /> );
	}
	
}

const VeranstaltungPage = (props) => {
	const { vnr, countErfassteTeilnehmer,
		refreshPunktemeldungen, changeEFN, showConfirmDialog, veranstaltung, meldeErfassteTeilnahmen, 
		removeAllSeized, erfassteEFNs, addTeilnahmeState, setScreen } = props;
	useEffect(() => {
		if( veranstaltung !== undefined ){
			
			refreshPunktemeldungen(vnr, veranstaltung.bearer)
		}	
	}, [vnr, veranstaltung, refreshPunktemeldungen]);
  const erfassteTeilnahmen = useSelector( state => state.punkteMeldung.erfassteTeilnahmen[vnr]);
  const teilnahmeMeldungen = useSelector( state => state.punkteMeldung.teilnahmeMeldungen[vnr]);
	if( veranstaltung === undefined) return ( <UnbekannteVNR /> );
	const showTeilnahmedatum = isShowTeilnahmedatum(veranstaltung)
	const veranstaltungLiegtInZukunft = isVeranstaltungsBeginnInZukunft(veranstaltung)
	const showWarningRefPunkte = !validateRefPunkte(veranstaltung, erfassteTeilnahmen, teilnahmeMeldungen);
	const showWarningGemeldeteRefPunkte = !validateGemeldeteRefPunkte(veranstaltung, teilnahmeMeldungen);
	const highLightRefpunkte = showWarningGemeldeteRefPunkte || showWarningRefPunkte;
	return(
		<>
		<EIVHeader></EIVHeader>
		<div className="container bg-light pt-2 pb-2">
			<div className="h3">Punktemeldung</div>
			<VeranstaltungsItem
				setScreen={setScreen}
				veranstaltung={veranstaltung} className={CSS_CLASSNAME_PANEL+ " container"}></VeranstaltungsItem>
			{ veranstaltungLiegtInZukunft?
			<VeranstaltungLiegtInZukunft veranstaltung={veranstaltung} />:
			<div>
				{ veranstaltung.gesperrt_fuer_veranstalter?
						<GesperrtFuerVeranstalter />:
					<>
					<div className="h4">Teilnahmen erfassen</div>
					<AddTeilnehmer veranstaltung = {veranstaltung} addTeilnahmeState = {addTeilnahmeState} >
						
					<Beispiele className="clickableValue" title="Beispiel EFNs ?">
						<div className="container">
							<div className="row">
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802740888888881")}}>802740888888881</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802730888888882")}}>802730888888882</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802720888888883")}}>802720888888883</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802720777777775")}}>802720777777775</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802720222222229")}}>802720222222229</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802720333333337")}}>802720333333337</span>&nbsp;

								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760600635865")}}>802760600635865</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760600693690")}}>802760600693690</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760600693682")}}>802760600693682</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760600693674")}}>802760600693674</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760300468856")}}>802760300468856</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760902428688")}}>802760902428688</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802761400132178")}}>802761400132178</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802761600288457")}}>802761600288457</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802761208933603")}}>802761208933603</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760300500344")}}>802760300500344</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802760902416220")}}>802760902416220</span>&nbsp;
								<span onClick={ (e) => {e.stopPropagation(); e.preventDefault(); changeEFN(vnr, "802761204191495")}}>802761204191495</span>
							</div>
						</div>
					</Beispiele>
					</AddTeilnehmer>
					{ showWarningRefPunkte &&
						<Alert color="warning">
							<h5 className="alert-heading">Warnung: Auffällig viele Ref/Mod/WL-Pkte.!</h5>
							<AlertContentWarningRefPunkte vnr={vnr} />
						</Alert>	
					}	
					<div className="h4">Erfasste Teilnahmen für die Meldung (noch nicht gemeldet) {countErfassteTeilnehmer > 0 ?countErfassteTeilnehmer:""}</div>
					<div className={CSS_CLASSNAME_PANEL}>
						{countErfassteTeilnehmer > 24 && 
							<div className='d-flex justify-content-between'>
								<Button onClick={ (e) => {
									showConfirmDialog(
										"Teilnahmen verbindlich melden", 
										"Die erfolgte Meldung kann nicht rückgängig gemacht werden. Sie sind für die Richtigkeit, Vollständigkeit und Rechtzeitigkeit der Meldung verantwortlich. Sollen die erfassten Daten gemeldet werden ?",
										(e) => { 
											meldeErfassteTeilnahmen(vnr);
											soundMeldungSent.play();
										} )
									}}   color="info">Verbindlich melden</Button>
									<Button title='Alle erfassten entfernen' outline="1" color="danger" onClick = { (e) => removeAllSeized(vnr, erfassteEFNs) }>
										Alle ({countErfassteTeilnehmer}) entfernen 
									</Button>
							</div>

						}	
						<ErfassteTeilnehmer showTeilnahmedatum={showTeilnahmedatum} vnr={vnr} highLightRefpunkte = {highLightRefpunkte} className={CSS_CLASSNAME_PANEL}></ErfassteTeilnehmer>
						{countErfassteTeilnehmer > 0 && 
							<div className='d-flex justify-content-between'>
								<Button onClick={ (e) => {
									showConfirmDialog(
										"Teilnahmen verbindlich melden", 
										"Die erfolgte Meldung kann nicht rückgängig gemacht werden. Sie sind für die Richtigkeit, Vollständigkeit und Rechtzeitigkeit der Meldung verantwortlich. Sollen die erfassten Daten gemeldet werden ?",
										(e) => { 
											meldeErfassteTeilnahmen(vnr);
											soundMeldungSent.play();
										} )
									}}   color="info">Verbindlich melden</Button>
								{countErfassteTeilnehmer > 9 &&
									<Button title='Alle erfassten entfernen' outline="1" color="danger" onClick = { (e) => removeAllSeized(vnr, erfassteEFNs) }>
										Alle ({countErfassteTeilnehmer}) entfernen 
									</Button>
								}

							</div>
						}
					</div>
					</>
				}
				{ showWarningGemeldeteRefPunkte && !showWarningRefPunkte &&
						<Alert color="warning">
							<h5 className="alert-heading">Warnung: Auffällig viele Ref/Mod/WL-Pkte. gemeldet!</h5>
							<AlertContentWarningRefPunkte vnr={vnr} />
						</Alert>	
					}	
				<div className="h4">Protokoll - erfolgte Punktemeldungen</div>
				<div className={CSS_CLASSNAME_PANEL}>
					<Punktemeldungen vnr={vnr} highLightRefpunkte = {highLightRefpunkte}></Punktemeldungen>
				</div>
			</div>
			}
		</div>
		</>
	)
}

const getErfasstenEFNs = stateTeilnahmen => {
	if(!stateTeilnahmen) return null;
	const teilnahmen = Object.values(stateTeilnahmen)
	const efns = teilnahmen.map( e => e.efn );
	const joinedEFNs = efns.join(" ");
	return joinedEFNs;
}
const mapStateToProps = (state) => {
	return {
		vnr : state.appNavigation.selectedVNR,
		veranstaltung : state.veranstaltungen[state.appNavigation.selectedVNR],
		countErfassteTeilnehmer : state.punkteMeldung.erfassteTeilnahmen[state.appNavigation.selectedVNR] 
					=== undefined?0:Object.keys(state.punkteMeldung.erfassteTeilnahmen[state.appNavigation.selectedVNR]).length,
		addTeilnahmeState : state.punkteMeldung.addTeilnahme[state.appNavigation.selectedVNR],
		erfassteEFNs: getErfasstenEFNs(state.punkteMeldung.erfassteTeilnahmen[state.appNavigation.selectedVNR])	

	}
}
const mapDispatchToProps = dispatch => ({
		showConfirmDialog: (title, message, onConfirm) => dispatch(showConfirmDialog(title, message, onConfirm)),
		meldeErfassteTeilnahmen: (vnr) => dispatch(meldeErfassteTeilnahmen(vnr)),
		removeAllSeized: (vnr, erfassteEFNs) => dispatch(removeAllSeized(vnr, erfassteEFNs)),
		changeEFN: (vnr, efn) => dispatch(addTeilnahmeSetEFN(vnr, efn)),
		refreshPunktemeldungen: (vnr, bearer) => dispatch(fetchPunktemeldungen(vnr, bearer)),
		setScreen : (screen) => dispatch(setScreen(screen))
})
export default connect(mapStateToProps, mapDispatchToProps)(VeranstaltungPage) 

function VeranstaltungLiegtInZukunft(props){
	const {veranstaltung} = props
	const beginnDatum = moment(veranstaltung.beginn).format('DD.MM.YYYY')
	return(
		<div className="jumbotron" role="alert">
			<h4 className="display-6">Die Veranstaltung beginnt in der Zukunft!</h4>
			<p className="lead">Die Teilnahmen können erst ab dem {beginnDatum} erfasst werden.</p>
		</div>
	)
}
function GesperrtFuerVeranstalter(props){
	return(
		<div className="jumbotron" role="alert">
			<h4 className="display-6">Die Veranstaltung ist geschlossen.</h4>
			<p className="lead">
				Die Teilnahmen können entweder vorübergehend oder gar nicht mehr gemeldet werden. Bei Fragen oder Unstimmigkeiten kontaktieren Sie bitte die zuständige Anerkennungsstelle.
			</p>
		</div>
	)
}

function UnbekannteVNR(props){
	return(
		<Alert color="info">
		<h4 className="alert-heading">Unbekannte VNR!</h4>
		 <p>
			 Für die angegebene VNR liegen keine Veranstaltungsdaten vor. Bitte laden Sie vorerst die Veranstaltung.
		 </p>
		 <div>
			 <Button href="/veranstaltungen" outline color="secondary">Veranstaltungen bitte hier laden</Button>
		 </div>
	 </Alert>
	)
}

function VeranstaltungsItem(props){
	const {veranstaltung} = props;
	const endeZeit = moment(veranstaltung.beginn).format('DD.MM.YYYY') !== moment(veranstaltung.ende).format('DD.MM.YYYY')?
		moment(veranstaltung.ende).format('DD.MM.YYYY HH:mm'):moment(veranstaltung.ende).format('HH:mm')
	return (<div className={`clickableValue container-fluid ${CSS_CLASSNAME_PANEL}`}>
			<div>{veranstaltung.vnr}</div>
			<div className="h5">{veranstaltung.thema}</div>
			<div>{veranstaltung.unterthema}</div>
			<div className="row text-muted ">
				<div className="col-12 col-sm-7 col-md-6 col-lg-8">Kat. ({veranstaltung.kategorie}) {moment(veranstaltung.beginn).format('DD.MM.YYYY HH:mm')} - {endeZeit}</div>
				<div className="col-12 col-sm-5 col-md-6 col-lg-4 text-right ">
					<Button  onClick={(e) =>  {e.preventDefault(); e.stopPropagation(); props.setScreen(appScreens.veranstalterPage)}} size="sm" outline color="info">zurück zur Veranstaltungsliste</Button>
				</div>
			</div>			
		{/* <span>{countErfasste}</span>
		<span>{gemeldeteTeilnehmer}</span> */}
	</div>);

}
/* 			
		<div className="text-right">
			<Button size="sm" color="primary">Teilnehmer verbindlich melden</Button>
			&nbsp;
			<Button size="sm" color="secondary" outline>Alle entfernen</Button>
		</div> */
