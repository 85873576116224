import { useRef } from 'react';
import { toast } from 'react-toastify';

const useDebouncedToast = (delay = 3000) => {
    const lastShownMessageInfoRef = useRef({ message: null, type: null });
    const lastShownMessageTimeRef = useRef(null);
    const lastShownMessageCounterSkippedRef = useRef(0);
  
    const showMessage = (message, type) => {
      switch (type) {
        case 'success':
          toast.success(message);
          break;
        case 'warning':
          toast.warning(message);
          break;
        case 'error':
          toast.error(message);
          break;
        case 'dark':
          toast.dark(message);
          break;
        default:
          toast.info(message);
      }
      const currentTime = Date.now();
      lastShownMessageInfoRef.current = { message, type };
      lastShownMessageTimeRef.current = currentTime;
      lastShownMessageCounterSkippedRef.current = 0;
    };
    /**
     * 
     * @param {*} message 
     * @param {*} type (success, warning, error, dark)
     * @param {*} callback 
     * @returns counter of skipped messages
     */
    const debouncedToast = (message, type = 'info', callback = undefined) => {
      const currentTime = Date.now();
      // console.log('before if lastShownMessageTime:', lastShownMessageTimeRef.current);
      // console.log('before if lastShownMessageInfo:', JSON.stringify(lastShownMessageInfoRef.current));
  
      if (
        lastShownMessageInfoRef.current.message === message &&
        lastShownMessageInfoRef.current.type === type &&
        lastShownMessageTimeRef.current &&
        currentTime - lastShownMessageTimeRef.current < delay
      ) {
        lastShownMessageCounterSkippedRef.current += 1;
        return lastShownMessageCounterSkippedRef.current;
      }
  
      showMessage(message, type);
      if(callback && typeof callback === 'function') callback();
      return lastShownMessageCounterSkippedRef.current;
    };
  
    return debouncedToast;
  };

export default useDebouncedToast;