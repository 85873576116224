import React from 'react'
import { connect } from 'react-redux'
import { pmSubscribe, pmCordovaSubscribe, unsubscribeEFN } from '../store/notifications/actions'
import { isUseCordovaPushNotification } from '../store/notifications/reducer'

const CurrentStatusBenachrichtigung = props => {
	const { notificationActivated } = props
	return(
		<>
		<h5>Bei neuen Punktemeldungen</h5>
		{ notificationActivated && 
			<p className="text-secondary">werden Sie per Push Nachricht informiert</p>
		}
		{ !notificationActivated && 
			<p className="text-secondary">Kann die Benachrichtigung per Push Nachricht eingerichtet werden</p>
		}
		</>
	)
}

const ActivateNotifications = props => {
	const { notificationsSupported, efnSubscribed, unsubscribe, efn, bearer, useCordovaPushNotification,
		subscribingPending = false, unsubscribingPending = false,
		subscriptionId, activateNotifications, className } = props
	
	if(!notificationsSupported && !useCordovaPushNotification) return null
	
	return(
		<>
		<CurrentStatusBenachrichtigung notificationActivated={efnSubscribed}/>
		<div className={className}>
		{!efnSubscribed &&
			<button type="button" className="btn btn-info btn-md-block" onClick={ () => !subscribingPending && activateNotifications(efn, bearer) } >Benachrichtigung aktivieren</button>
		}
		{efnSubscribed &&
			<button type="button" className="btn btn-secondary btn-md-block" onClick={ () => !unsubscribingPending && unsubscribe(efn, bearer, subscriptionId) } >Benachrichtigung deaktivieren</button>
		}
		{props.children}
		</div>
		</>
	)
}

const mapStateToProps = (state, ownProps) => (
	{
		efnSubscribed: state.notifications.efnSubscriptionId !==null,
		notificationsSupported : state.notifications.supported,
		useCordovaPushNotification: state.notifications.useCordovaPushNotification,
		efn : state.efnHolder.efn,
		bearer : state.efnHolder.bearer,
		subscriptionId: state.notifications.efnSubscriptionId,
		subscribingPending : state.efnHolder.subscribingPending,
		unsubscribingPending : state.efnHolder.unsubscribingPending
	}
)

const putActionsToProps = dispatch => {
	return {
		 unsubscribe: (efn, bearer, subscriptionId) => dispatch(unsubscribeEFN(efn, bearer, subscriptionId)),
		 activateNotifications: (efn, bearer) => isUseCordovaPushNotification()?
				dispatch(pmCordovaSubscribe(efn, bearer)):dispatch(pmSubscribe(efn, bearer)),
	}
};

export default connect(mapStateToProps, putActionsToProps)(ActivateNotifications);