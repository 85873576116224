import React from 'react';
// import logo from './resources/logo65x65.png';
import i18next from "i18next"
import {  NavbarBrand  } from 'reactstrap';
import { setScreen, appScreens, displayDialogQrcodeLinkToApp } from './store/appNavigation/actions';
import {connect} from 'react-redux'
import { createAnId } from './EIVCommons';
import { mobileAppModus } from './EivngCore';

class EIVHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    const anId = createAnId();
    const title = process.env.REACT_APP_HEADER_TITLE || i18next.t('eiv.header');
    const subTitle = process.env.REACT_APP_HEADER_SUBTITLE  || i18next.t('eiv.header.subtitile');
    return (
      
        <header className="navbar fixed-top navbar-light bg-white" >
          <button onClick={this.toggleNavbar} className="mr-2 navbar-toggler" type="button" data-toggle="dropdown" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <NavbarBrand onClick={e => this.props.setScreen(appScreens.startPage)} className="mr-auto">
          {/* <img className = "mr-2" src={logo} size="" alt="logo"/> */}
          <div className="ml-2 d-inline-block">
                <div className="mb-0 mt-0 h5">{title}</div>
                <div className="h6 font-weight-light pt-0">{subTitle}</div>
           </div>
          </NavbarBrand>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <div className="dropdown-menu dropdown-menu-left" aria-labelledby={anId}>
            <div className="dropdown-item clickableValue"  onClick={e => this.props.setScreen(appScreens.startPage)}>
              Startseite
            </div>
            <div className="dropdown-item clickableValue"  onClick={e => this.props.setScreen(appScreens.veranstalterPage)}>
            Punktemeldung
            </div>
            <div className="dropdown-item clickableValue"  onClick={e => this.props.setScreen(appScreens.setupEFNPage)}>
            Teilnehmer mit EFN
            </div>
            <div className="dropdown-divider"></div>
            <div className="dropdown-item clickableValue"  onClick={e => window.open('https://punkte.eiv-fobi.de/datenschutz.html', '_blank')}>
              Datenschutz
             </div>
            <div className="dropdown-item clickableValue"  onClick={e => window.open('https://punkte.eiv-fobi.de/impressum.html', '_blank')}>
              Impressum
             </div>
  { !mobileAppModus &&
            <>
              <div className="dropdown-divider"></div>
              <div className="dropdown-item clickableValue"  onClick={e => this.props.openModalQRCodeZurApp()}>
              Link zur App anzeigen
              </div>
            </>
  }
          </div>
          </div>
        </header>
      
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setScreen : (screen) => dispatch(setScreen(screen)),
    openModalQRCodeZurApp : () => dispatch(displayDialogQrcodeLinkToApp(true))
	}
};
export default connect(null, mapDispatchToProps)(EIVHeader)