import React from 'react';
import EIVHeader  from './EIVHeader';
import { Button, Form, FormGroup, Label, Jumbotron } from 'reactstrap';
import Switch from "react-switch";
import { connect } from 'react-redux';
import { eivngCore, mobileAppModus } from './EivngCore'
import { GIT_COMMIT_HASH } from "./git-commit-hash";
// import { CSS_CLASSNAME_PANEL } from './EIVCommons'
import { setScreen, appScreens, setSaveStateToLocalStorage } from './store/appNavigation/actions';

 const styleJumbotron = {
	// backgroundColor: "#f8f9fa"
 }

 function StartPage(props) {
	const  { setSaveStateToLocalStorage, saveStateToLocalStorage = false } = props
	return (
		<div className="container">
			<EIVHeader></EIVHeader>
			<Jumbotron className ="bg-white" style={styleJumbotron}>
			<h1 className="display-4">Willkommen!</h1>
			<p className="lead">
				Mit dieser Applikation können Sie als Veranstalter einer anerkannten Fortbildungsveranstaltung die Teilnahme und erworbenen 
				Fortbildungspunkte Ihrer Teilnehmer melden.
			</p>
			<p className="lead">Als Teilnehmer einer Veranstaltung können Sie sehen, ob und wann der Veranstalter Ihre FOBI Punkte gemeldet hat, 
			zusätzlich können Sie die Benachrichtigung daüber einrichten. 
			</p>
			<Button className="mt-5 mb-4" onClick={e => props.setScreen(appScreens.veranstalterPage)} block size="lg" color="info">zur Punktemeldung (als Veranstalter)</Button>
			<Button onClick={e => props.setScreen(appScreens.setupEFNPage)} block size="lg" color="info">Als Teilnehmer mit EFN</Button>&nbsp;
			<Form>
				<FormGroup>
					<Label check>
						<Switch onColor="#138496" onChange={() => { saveStateToLocalStorage?eivngCore.resetSettings():setSaveStateToLocalStorage(true) }} checked={saveStateToLocalStorage} />
						<span className="mx-2 lead">Daten lokal im Browser speichern</span>
					</Label>
				</FormGroup>
			</Form>
			</Jumbotron>
			{/* <div className={CSS_CLASSNAME_PANEL}> */}
			<Jumbotron className ="bg-white" style={styleJumbotron}>
			{ saveStateToLocalStorage &&
			<>
			{ !mobileAppModus &&
				<p className="lead">
					Der Modus für private Geräte ist derzeit aktiviert. 
					In diesem Modus werden Ihre Eingaben und Daten im lokalen Speicher (LocalStorage) Ihres Geräts gesichert. 
					Dadurch bleiben Ihre Daten auch nach dem Schließen des Browsers erhalten und sind bei Ihrem nächsten Besuch der 
					Applikation weiterhin verfügbar. 
					Dieser Modus eignet sich ideal für die Nutzung auf Ihrem persönlichen Rechner oder mobilen Geräten, 
					um einen bequemen und sicheren Zugang zu Ihren Daten zu gewährleisten.
				</p>
			}
			{/* <Button className="mt-4 mb-4" 
				onClick={() => { eivngCore.resetSettings()} } title = "Alle lokal gespeicherte Daten löschen. Modus für öffentliche Rechner aktivieren."
				block size="lg" color="warning">Auslieferungszustand wiederherstellen</Button> */}
			</>
			}
			{ !saveStateToLocalStorage &&
			<>
			<p className="lead">
				Der Modus für öffentliche Rechner ist derzeit aktiviert. 
				In diesem Modus werden alle Benutzereingaben und eingelesene Daten nach dem Schließen des Browsers nicht gespeichert und gehen verloren.</p>
			<p className="lead">
				Wenn Sie die Applikation auf Ihrem eigenen Rechner oder mobilen Geräten verwenden, 
				empfehlen wir Ihnen, den Modus für private Geräte zu aktivieren. 
				In diesem Modus werden Ihre Eingaben und Daten im lokalen Speicher (LocalStorage) Ihres Geräts gesichert, 
				sodass sie auch nach dem Schließen des Browsers erhalten bleiben und bei Ihrem nächsten Besuch der Applikation wieder verfügbar sind. 
				Dies bietet Ihnen zusätzlichen Komfort und Sicherheit beim Erfassen und Verwalten Ihrer Daten.</p>
				{/* <Button className="mt-4 mb-4" 
				onClick={() => { setSaveStateToLocalStorage(true)} } title = "Daten der Applikation lokal speichern. (Lokal Storage)"
				block size="lg" color="secondary">Modus für private Geräte aktivieren</Button> */}
			</>	
			}
			<div className='text-right mb-2'>
				<small className='text-muted'>git commit hash: {GIT_COMMIT_HASH}</small>
			</div>
			</Jumbotron>
			{/* </div> */}
		</div>
	);
}
const putStateToProps = (state) => {
	return { 
		saveStateToLocalStorage : state.appNavigation.saveStateToLocalStorage,
	};
}
const putActionsToProps = dispatch => {
	return {
		setScreen : (screen) => dispatch(setScreen(screen)),
		setSaveStateToLocalStorage: (flagEnabled) => dispatch(setSaveStateToLocalStorage(flagEnabled))
	}
};

const WrappedStartPage = connect(putStateToProps, putActionsToProps)(StartPage);
export default WrappedStartPage;
