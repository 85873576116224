import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

export const Beispiele = (props) => {
	const [ collapsed, setCollapsed ] = useState(true)
	const {title = "Beispiele", children, className : classNameAddOn = ""} = props
	const icon = collapsed?
<IoIosArrowDown fontSize="1em"  ></IoIosArrowDown>:
<IoIosArrowUp fontSize="1em" color="orange" ></IoIosArrowUp>
	return(
		<div className = {" " + classNameAddOn}>
		<div  onClick={ () => setCollapsed(!collapsed)}>{title} {icon}</div>
		{!collapsed &&
			<div className={props.className}>
				{children}
			</div>
		}
		</div>
	)
}